var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        okButtonProps: { props: { disabled: _vm.disableSubmit } },
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _vm.editStatus
        ? _c(
            "a-spin",
            { attrs: { spinning: _vm.confirmLoading } },
            [
              _c(
                "a-form",
                { attrs: { form: _vm.form } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "客户姓名",
                        hasFeedback: "",
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入客户姓名!",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "['name', {rules: [{ required: true, message: '请输入客户姓名!' }]}]",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入客户姓名",
                          readOnly: _vm.disableSubmit,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "性别",
                        hasFeedback: "",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: ["sex", {}],
                              expression: "['sex', {}]",
                            },
                          ],
                          attrs: { placeholder: "请选择性别" },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "1" } }, [
                            _vm._v("男性"),
                          ]),
                          _c("a-select-option", { attrs: { value: "2" } }, [
                            _vm._v("女性"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "身份证号码",
                        hasFeedback: "",
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["idcard", _vm.validatorRules.idcard],
                            expression: "['idcard', validatorRules.idcard]",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入身份证号码",
                          readOnly: _vm.disableSubmit,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "身份证扫描件",
                        hasFeedback: "",
                      },
                    },
                    [
                      _c("j-image-upload", {
                        attrs: { text: "上传", isMultiple: true },
                        model: {
                          value: _vm.fileList,
                          callback: function ($$v) {
                            _vm.fileList = $$v
                          },
                          expression: "fileList",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "联系方式",
                        hasFeedback: "",
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["telphone", _vm.validatorRules.telphone],
                            expression:
                              "[ 'telphone', validatorRules.telphone]",
                          },
                        ],
                        attrs: { readOnly: _vm.disableSubmit },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "订单号码",
                        hidden: _vm.hiding,
                        hasFeedback: "",
                      },
                      model: {
                        value: this.orderId,
                        callback: function ($$v) {
                          _vm.$set(this, "orderId", $$v)
                        },
                        expression: "this.orderId",
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["orderId", {}],
                            expression: "[ 'orderId', {}]",
                          },
                        ],
                        attrs: { disabled: "disabled" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }